<div class="DivPageContainer" id="divMainLandingContainer">
    <section class="SectionMain" [ngStyle]="getImageMain()">
        <img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
        <img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
        <div class="DivFormHorizontalContainer" id="divMainLanding">
            <div class="DivForm2Column DivHeaderCenterContainer">
				<div class="DivHeaderContentContainer">
                    <h1 class="FontSoftColor">{{_modelHeaderContent.TitleIDN}}</h1>
                    <h5 class="FontSoftColor">{{_modelHeaderContent.SubTitleIDN}}</h5>
                    <p class="FontSoftColor Width100">{{_modelHeaderContent.DescriptionIDN}}</p>
                    <button class="ButtonSubmit" *ngIf="_modelUserSignIn === null || _modelUserSignIn === undefined" (click)="goToSignIn();"><label class="FontSoftColor">Login</label></button>
                </div>
                <!-- <div class="DivContentSearchContainer">
                    <div class="DivContentSearch">
                        <img src="../../assets/icons/sakina/icon_search.svg"  alt="Search">
                        <div class="DivContentSearchDetailFilter">
                            <input type="text" placeholder="Cari di disini">
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="DivForm2Column DivSocialMediaCenterContainer">
                <div class="DivSocialMedia">
                    <img [src]="socialMedia.Path" *ngFor="let socialMedia of _arraySocialMedia" (click)="goToSNS(socialMedia.Link!)">
                </div>
            </div>
        </div>
    </section>

    <section class="SectionSecondary">
        <div class="DivFormHorizontalContainer DivSiteContainer" id="divSiteLandingContainer">
            <div class="DivContentBox DivRelativeContainer Padding0" [ngStyle]="getImageBrinsDigital()">
                <button class="ButtonSubmit" (click)="setBooleanVideo(true)"><label class="FontSoftColor">Lihat Video</label></button>
            </div>
            <div class="DivForm2Column FlexGrow1 FlexShrink1" id="divParagraphLanding">
				<ng-container *ngFor="let modelLandingContent of _arrayModelLandingContent">
					<h3>{{modelLandingContent.Title}}</h3>
					<p [innerHTML]="modelLandingContent.Description"></p>
				</ng-container>
            </div>
            <div class="DivForm2Column FlexGrow3 FlexShrink1" id="divProductLanding">
                <ul class="UlTabContainer">
                    <li class="ListTab" [ngClass]="{ 'ListActive' : showProductPeriode(_enumPeriodeProduct.Yearly) }" (click)="setProductPeriode(_enumPeriodeProduct.Yearly)">Tahunan</li>
                    <li class="ListTab" [ngClass]="{ 'ListActive' : showProductPeriode(_enumPeriodeProduct.Monthly) }" (click)="setProductPeriode(_enumPeriodeProduct.Monthly)">Bulanan</li>
                    <li class="ListTab" [ngClass]="{ 'ListActive' : showProductPeriode(_enumPeriodeProduct.Daily) }" (click)="setProductPeriode(_enumPeriodeProduct.Daily)">Harian</li>
                </ul>

                <div class="DivContentBox DivContentImage" id="divProductContainer">
                    <div class="DivFormHorizontalContainer Width100">
                        <div class="DivProduct" *ngFor="let modelProduct of this._arrayModelProduct" (click)="handleOpenAnnouncement()">
                            <img [src]="modelProduct.Path">
                            <label>{{ modelProduct.Name }}</label>
                        </div>
                    </div>
                    <div class="DivFormHorizontalContainer Width100">
                        <div class="DivProduct" *ngFor="let modelProductAsmik of this._arrayModelProductAsmik" (click)="handleOpenAnnouncement()">
                            <img [src]="modelProductAsmik.Path">
                            <label>{{ modelProductAsmik.Name }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- START MODAL -->

<div class="DivModal" [ngStyle]="_booleanModalVideo ? {'display': 'block'} : {'background-color': 'none'}" *ngFor="let modelLandingContent of _arrayModelLandingContent">
	<div class="DivModalHeader">
		<h2 class="MarginBottom0">{{modelLandingContent.Title}}</h2>
		<img (click)="setBooleanVideo(false)" src="../../assets/icons/sakina/icon_close.svg">
	</div>
	<div class="DivModalFigureContainer">
		<figure class="DivModal">
			<iframe [src]="_stringURLAboutBRINSEmbed | safe" frameborder="0" allow="autoplay" allowfullscreen></iframe>
		</figure>
	</div>
</div>

<!-- END MODAL -->


<!-- START MODAL ANNOUNCEMENT MAINTENANCE -->

<div id="divModal" *ngIf="_booleanModalAnnouncement">
    <div id="divBubleModal" class="DivBubbleModal">
        <span>{{ _stringConstant.STRING_ANNOUNCEMENT_MAINTENANCE_PRODUCT_TITLE }}</span>
        <label>{{ _stringConstant.STRING_ANNOUNCEMENT_MAINTENANCE_PRODUCT_DESCRIPTION }}</label>
        <input type="button" id="buttonPositive" value="Kembali" (click)="handleCancelAnnouncement()">
    </div>
</div>

<!-- END MODAL ANNOUNCEMENT MAINTENANCE -->