//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkStrategy } from "ngx-quicklink";
import { CartComponent } from "./components/cart/cart.component";
import { CheckoutComponent } from "./components/checkout/checkout.component";
import { HomeComponent } from "./components/home/home.component";
import { LandingComponent } from "./components/landing/landing.component";
import { MobileApplicationComponent } from "./components/mobileapplication/mobileapplication.component";
import { PartnerComponent } from "./components/partner/partner.component";
import { SigninComponent } from "./components/signin/signin.component";
import { StringConstant } from "./constants/string.constant";
import { FlexibleGuardService } from "./services/guards/flexible.guard.service";
import { PublicGuardService } from "./services/guards/public.guard.service";


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full"
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [FlexibleGuardService],
		children:
		[
			{
				path: "",
				component: LandingComponent,
			},
			// {
			// 	path: "product",
			// 	loadChildren:() => import("./components/product/product.module").then(pageModule => pageModule.ProductModule)
			// },
			// {
			// 	path: "productasmik",
			// 	loadChildren: () => import("./components/productasmik/productasmik.module").then(pageModul => pageModul.ProductASMIKModule)
			// },
			// {
			// 	path: "productlist",
			// 	component: ProductListComponent,
			// },
			// {
			// 	path: "policy",
			// 	loadChildren: () => import("./components/policy/policy.module").then(pageModule => pageModule.PolicyModule)
			// },
			{
				path: "mobileapplication",
				component: MobileApplicationComponent,
			},
			{
				path: "cart",
				component: CartComponent,
			},
			{
				path: "promotion",
				loadChildren: () => import("./components/promotion/promotion.module").then(pageModul => pageModul.PromotionModule)
			},
			{
				path: "partner",
				component: PartnerComponent
			},
		],
	},
	// {
	// 	path: "payment",
	// 	component: HomeComponent,
	// 	canActivate: [FlexibleGuardService],
	// 	children:
	// 	[
	// 		{
	// 			path: ":" + StringConstant.STRING_ROUTING_KEY_TOKEN,
	// 			component: PaymentComponent
	// 		}
	// 	]
	// },
	{
		path: "checkout",
		component: HomeComponent,
		canActivate: [FlexibleGuardService],
		children:
		[
			{
				path: ":" + StringConstant.STRING_ROUTING_KEY_TOKEN,
				component: CheckoutComponent
			}
		]
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy,
					scrollPositionRestoration: "top"
				}
			)
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
