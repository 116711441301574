<div class="DivBodyContainer" (scroll)="scrolled($event)">
    <div id="divBodyHomeMenu">
		<nav id="navigationMainHome" [ngClass]="_booleanMenuMaximize ? 'NavHeaderMaximize' : 'NavHeaderMinimize' " (click)="!_booleanMenuMaximize && minimizeMenu(true)">
			<img id="imageLogoHeader" (click)="goToMainDomain()" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo BRI Insurance">
			<ng-container *ngIf="_booleanMenuMaximize">
				<ul *ngIf="_booleanMenuMaximize">
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Home) }" (click)="goToHome()">
						<label>Beranda</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Product) }" (click)="handleOpenAnnouncement(_stringConstant.STRING_MENU_PRODUCT);">
						<label>Produk</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Policy) }" (click)="handleOpenAnnouncement(_stringConstant.STRING_MENU_POLICY);">
						<label>Polis</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.MobileApplication) }" (click)="goToMobileApplication();">
						<label>Aplikasi Mobile</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.BRINSAPI) }" (click)="goToBRINSApi();">
						<label>BRINS API</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Partner) }" (click)="goToPartner();">
						<label>Mitra</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Promo) }" (click)="goToPromo();">
						<label>Promo</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Contact) }" (click)="goToContact();">
						<label>Kontak</label>
					</li>
				</ul>

				<div class="DivNavigationRight">
					<img id="imageLogoCart" src="../../assets/icons/sakina/icon_cart.svg" alt="BRI Insurance - Logo Header Cart" (click)="handleOpenAnnouncement(_stringConstant.STRING_MENU_PRODUCT);">
					<li class="ListNavigationMenu">
						<a class="ANavigationMenu"><img class="ImageIconMenu" src="../../assets/icons/sakina/icon_profile.svg"></a>
						<div class="DivNavigationDetail">
							<ul>
								<ng-container *ngIf="_modelUserSignIn.FirstName !== null && _modelUserSignIn.FirstName !== undefined && _modelUserSignIn.FirstName !== ''">
									<li>
										<a class="ANavigationMenuList" (click)="goToMainDomain()">Profil</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="callServiceSignOut()">Keluar</a>
									</li>
								</ng-container>

								<li *ngIf="_modelUserSignIn.FirstName === null || _modelUserSignIn.FirstName === undefined || _modelUserSignIn.FirstName === ''">
									<a class="ANavigationMenuList" (click)="goToSignIn()">Masuk</a>
								</li>
							</ul>
						</div>
					</li>
					<img id="imageLogoDigital" (click)="goToHome()" src="../../assets/logos/sakina/logo_insurtech.svg" alt="BRI Insurance - Logo Header Digital">
					<!-- <img (click)="minimizeMenu(false)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_close.svg"> -->
				</div>
			</ng-container>

			<ng-container *ngIf="!_booleanMenuMaximize">
				<div class="DivNavigationRight">
					<ul>
						<li>
							<img id="imageIconHamburger" (click)="minimizeMenu(true)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_hamburger_menu.svg">
						</li>
					</ul>
				</div>
			</ng-container>
		</nav>
		
		<nav id="navigationAside" class="NavMenuOverlay" [ngClass]="_booleanMenuBarMinimize ? 'NavMenuMinimizeOverlay' : 'NavMenuMinimizeNoOverlay'" >

			<div class="DivMenuHeader">
				<img id="imageLogoDigital" (click)="goToHome()" src="../../assets/logos/sakina/logo_insurtech.svg" alt="BRI Insurance - Logo Header Digital">
				<img (click)="minimizeMenu(false)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_close.svg">
			</div>

			<ul *ngIf="!_booleanMenuMaximize" class="UlMenuMinimize ListNavigation">
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Home) }" (click)="goToHome()">
					<label>Beranda</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Product) }" (click)="handleOpenAnnouncement(_stringConstant.STRING_MENU_PRODUCT);">
					<label>Produk</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Policy) }" (click)="handleOpenAnnouncement(_stringConstant.STRING_MENU_POLICY);">
					<label>Polis</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.MobileApplication) }" (click)="goToMobileApplication();">
					<label>Aplikasi Mobile</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.BRINSAPI) }" (click)="goToBRINSApi();">
					<label>BRINS API</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Partner) }" (click)="goToPartner();">
					<label>Mitra</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Promo) }" (click)="goToPromo();">
					<label>Promo</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Contact) }" (click)="goToContact();">
					<label>Kontak</label>
				</li>
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerTicket">Profil</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTicket" [checked]="this._booleanNavigationItem" [(ngModel)]="_booleanNavigationItem">
					<ul class="ListSubNavigation" *ngIf="_modelUserSignIn.FirstName !== null && _modelUserSignIn.FirstName !== undefined && _modelUserSignIn.FirstName !== ''">
						<li class="ListItemSubNavigation" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Profile) }">
							<label (click)="goToMainDomain()">Profil</label>
						</li>
						<li class="ListItemSubNavigation">
							<label (click)="callServiceSignOut()">Keluar</label>
						</li>
					</ul>
					<ul class="ListSubNavigation" *ngIf="_modelUserSignIn.FirstName === null || _modelUserSignIn.FirstName === undefined || _modelUserSignIn.FirstName === ''">
						<li class="ListItemSubNavigation">
							<label (click)="goToSignIn()">Masuk</label>
						</li>
					</ul>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Cart) }" (click)="handleOpenAnnouncement(_stringConstant.STRING_MENU_PRODUCT);">
					<label>Keranjang</label>
				</li>
				<li>
					<img id="imageMainDomain" (click)="goToMainDomain()" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo BRI Insurance">
				</li>
			</ul>
		</nav>
	</div>

    <div id="divMainDashboard">
		<router-outlet></router-outlet>
		<app-footer></app-footer>
	</div>
</div>

<!-- START MODAL ANNOUNCEMENT MAINTENANCE -->

<div id="divModal" *ngIf="_booleanModalAnnouncement">
    <div id="divBubleModal" class="DivBubbleModal">
        <span>{{ _stringAnnoucementTitle }}</span>
        <label>{{ _stringConstant.STRING_ANNOUNCEMENT_MAINTENANCE_PRODUCT_DESCRIPTION }}</label>
        <input type="button" id="buttonPositive" value="Kembali" (click)="handleCancelAnnouncement()">
    </div>
</div>

<!-- END MODAL ANNOUNCEMENT MAINTENANCE -->